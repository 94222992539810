import React from "react"

export const RubleIcon = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="20" fill="#003791" />
    <path
      d="M16.5 27V13H22.5C23.2956 13 24.0587 13.3161 24.6213 13.8787C25.1839 14.4413 25.5 15.2044 25.5 16C25.5 16.7956 25.1839 17.5587 24.6213 18.1213C24.0587 18.6839 23.2956 19 22.5 19H14.5M22.5 23H14.5"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const CalendarIcon = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="20" fill="#003791" />
    <path
      d="M12.75 16.9143H26.75M14.5595 11V12.543M24.75 11V12.5428M27.75 15.2429V26.3C27.75 27.7912 26.5561 29 25.0833 29H14.4167C12.9439 29 11.75 27.7912 11.75 26.3V15.2428C11.75 13.7517 12.9439 12.5428 14.4167 12.5428H25.0833C26.5561 12.5428 27.75 13.7517 27.75 15.2429Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const HourglassIcon = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="20" fill="#003791" />
    <path
      d="M17.8245 25.9143H22.6755M25.2287 29H15.2713C14.7072 29 14.25 28.5395 14.25 27.9714V25.2237C14.25 25.0042 14.3198 24.7904 14.4491 24.6136L17.3781 20.6101C17.6435 20.2473 17.6435 19.7527 17.3781 19.3899L14.4491 15.3864C14.3198 15.2096 14.25 14.9958 14.25 14.7763V12.0286C14.25 11.4605 14.7072 11 15.2713 11H24.9734C25.5374 11 25.9947 11.4605 25.9947 12.0286V14.7961C25.9947 15.0034 25.9325 15.2058 25.8163 15.3769L23.0851 19.3971C22.8398 19.7581 22.8481 20.2359 23.1058 20.5881L26.0509 24.6136C26.1802 24.7904 26.25 25.0042 26.25 25.2237V27.9714C26.25 28.5395 25.7928 29 25.2287 29Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const WalletIcon = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="20" fill="#003791" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.3999 14.8001C9.39985 13.1433 10.743 11.8 12.3999 11.8H26.6403C28.2972 11.8 29.6403 13.1432 29.6403 14.8V16.4807C30.174 16.5017 30.6003 16.941 30.6003 17.4799V22.7599C30.6003 23.2988 30.174 23.7381 29.6403 23.7591V25.2001C29.6403 26.8569 28.2972 28.2001 26.6403 28.2001H12.4002C10.7434 28.2001 9.40029 26.857 9.40024 25.2001L9.3999 14.8001ZM28.6003 21.7599V18.4799H26.4803C25.5746 18.4799 24.8403 19.2142 24.8403 20.1199C24.8403 21.0257 25.5746 21.7599 26.4803 21.7599H28.6003ZM27.6403 23.7599V25.2001C27.6403 25.7523 27.1926 26.2001 26.6403 26.2001H12.4002C11.848 26.2001 11.4003 25.7524 11.4002 25.2001L11.3999 14.8001C11.3999 14.2478 11.8476 13.8 12.3999 13.8H26.6403C27.1926 13.8 27.6403 14.2478 27.6403 14.8V16.4799H26.4803C24.47 16.4799 22.8403 18.1096 22.8403 20.1199C22.8403 22.1302 24.47 23.7599 26.4803 23.7599H27.6403Z"
      fill="white"
    />
  </svg>
)

export const chooseIcon = (index: number) => {
  switch (index) {
    case 0: {
      return <RubleIcon />
    }
    case 1: {
      return <CalendarIcon />
    }
    case 2: {
      return <HourglassIcon />
    }
    default:
      return undefined
  }
}
