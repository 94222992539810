import React, { ReactElement } from "react"
import { IconRuble } from "./IconRuble"
import { IconBag } from "./IconBag"
import {
  CalendarIcon,
  RubleIcon,
  WalletIcon,
} from "../NakopitelnyeScheta/AdvantagesWithSeparateBlock/Icons"

export const DEFAULT_ITEMS = [
  {
    sup2: "до",
    count: "10",
    sup: "%",
    text1: "кэшбэк",
    text2: "",
  },
  {
    sup2: "",
    count: "0",
    sup: "₽",
    text1: "оформление",
    text2: "и обслуживание",
  },
  {
    sup2: "",
    count: "10",
    sup: "",
    text1: "10 месяцев",
    text2: "рассрочки",
  },
]

export const OTHER_ITEMS = [
  {
    sup2: "до",
    count: "10",
    sup: "%",
    text1: "кэшбэк",
    text2: "",
  },
  {
    sup2: "",
    count: "0",
    sup: "₽",
    text1: "оформление",
    text2: "и обслуживание",
  },
  {
    sup2: "до",
    count: "24",
    sup: "",
    text1: "месяцев",
    text2: "рассрочки",
  },
]

export const PARTNERS_ITEMS = [
  {
    sup2: "",
    count: "",
    icon: <IconRuble />,
    sup: "",
    text1: "рефинансирование",
    text2: "кредитных карт",
  },
  {
    sup2: "",
    count: "0",
    sup: "₽",
    text1: "бесплатное",
    text2: "обслуживание",
  },
  {
    sup2: "",
    count: "",
    icon: <IconBag />,
    sup: "",
    text1: "рассрочка",
    text2: "на покупки",
  },
]

export const positions = [
  {
    xs: {
      left: -156,
      afterRight: "4em",
    },
    sm: {
      left: -92,
      afterRight: "11.5em",
    },
    md: {
      left: -166,
      afterRight: "4em",
    },
    lg: {
      left: -88,
      afterRight: "4em",
    },
  },
  {
    xs: {
      left: -132,
      afterRight: "4em",
    },
    sm: {
      left: -81,
      afterRight: "11.5em",
    },
    md: {
      left: -155,
      afterRight: "4em",
    },
    lg: {
      left: -77,
      afterRight: "4em",
    },
  },
]

export interface IItem {
  title: string
  description: string
  icon?: ReactElement
}

export const ADVANTAGES_ITEMS_DEFAULT: IItem[] = [
  { title: "Пополнение", description: "в любое время" },
  { title: "Высокий доход", description: "на выгодных условиях" },
  { title: "Гибкие сроки ", description: "без ограничений" },
  { title: "Проценты", description: "начисляют каждый месяц" },
]

export const ADVANTAGES_ITEMS_APPLY_CARDS: IItem[] = [
  { title: "180 дней", description: "период без %", icon: <CalendarIcon /> },
  { title: "До 300 000 ₽", description: "кредитный лимит", icon: <RubleIcon /> },
  { title: "0 ₽", description: "за снятие наличных", icon: <WalletIcon /> },
  { title: "Доставка", description: "кредита бесплатная" },
]
